import Vue from "vue";
import Router from "vue-router";
import store from '../src/core/services/store/index';
import { SAVE_INVITATION_PARAMS } from "@/core/services/store/event.module";
import { SAVE_LAST_PATH } from "@/core/services/store/auth.module";

// import store from '../services/store/index';

Vue.use(Router);

function SaveInvitationUser(params){
  store.dispatch(SAVE_INVITATION_PARAMS, {
    params
  })
}

function SaveLastPath(params){
  store.dispatch(SAVE_LAST_PATH, {
    params
  })
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/system",
      redirect: "/system",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/client-dashboard",
          name: "client-dashboard",
          component: () => import("@/view/pages/ClientViews/ClientDashboard.vue"),
          meta: {
            requiresAuth: true,
            clientAuth: true,
            adminAuth: true,
          }
        },
        {
          path: "/evento-dashboard",
          name: "evento-dashboard",
          component: () => import("@/view/pages/AdminViews/EventDashboard/EventDashboard.vue"),
          meta: {
            requiresAuth: true,
            adminAuth: true,
          }
        },
        {
          path: "/theme-design",
          name: "theme-design",
          component: () => import("@/view/pages/AdminViews/EventDashboard/ThemeDesign/ThemeDesign.vue"),
          meta: {
            requiresAuth: true,
            adminAuth: true,
          }
        },
        {
          path: "/clients-dashboard",
          name: "clients-dashboard",
          component: () => import("@/view/pages/AdminViews/ClientDashboard/ClientsDashboard"),
          meta: {
            requiresAuth: true,
            adminAuth: true,
          }
        },
      ],
    },
    {
      path: "/",
      name: "plantilla-1",
      component: () => import("@/view/pages/Invitaciones/Principal.vue"),
      meta: {
      }
    },
    {
      path: "/ejemplo-boda",
      name: "ejemplo-boda",
      component: () => import("@/view/pages/Invitaciones/ejemplo-boda/ejemploBoda.vue"),
      meta: {
        InvitationType: true,
      }
    },
    {
      path: "/ejemplo-invitacion-normal",
      name: "ejemplo-invitacion-normal",
      component: () => import("@/view/pages/Invitaciones/ejemplo-formulario/ejemploInvitacionNormal.vue"),
      meta: {
        InvitationType: true,
      }
    },
    {
      path: "/ejemplo-invitacion-codigo/:code?",
      name: "ejemplo-invitacion-codigo",
      component: () => import("@/view/pages/Invitaciones/ejemplo-formulario/ejemploInvitacionCodigo.vue"),
      meta: {
        InvitationType: true,
      }
    },
    {
      path: "/slug-hernandez",
      name: "slug-hernandez",
      component: () => import("@/view/pages/Invitaciones/slug-hernandez.vue"),
      meta: {
        InvitationType: true,
      }
    },
    {
      path: "/slug-gerardo1/:code?",
      name: "slug-gerardo1",
      component: () => import("@/view/pages/Invitaciones/slug-gerardo1.vue"),
      meta: {
        InvitationType: true,
      }
    },
    {
      path: "/evento-chido/:code?",
      name: "evento-chido",
      component: () => import("@/view/pages/Invitaciones/evento-chido.vue"),
      meta: {
        InvitationType: true,
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "/error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "/error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "/error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "/error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "/error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "/error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    /* {
      path: "",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    }, */
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
})

router.beforeEach((to, from, next) => {
  SaveLastPath(from)
  if (to.meta.requiresAuth) {
    const isLoggedIn = store.getters['currentUser']
    if (Object.keys(isLoggedIn).length === 0) {
      next('/')
    } else {
      const profile = store.getters['currentUser'].user.idProfile
      console.log(profile);
      if (to.meta.clientAuth) {
        if (profile === 2 || profile === 1) {
          next()
        } else {
          next('/evento-dashboard')
        }
      }
      else if (to.meta.adminAuth) {
        if (profile === 1) {
          next()
        } else {
          next('/client-dashboard')
        }
      }
    }
  } else {
    if(to.meta.InvitationType){
      SaveInvitationUser(to.params);
    }
    next()
  }
})

export default router;



/* {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            requiresAuth: true,
            adminAuth: true,
            clientAuth: false,
          }
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
          meta: {
            requiresAuth: true,
            adminAuth: true,
            clientAuth: false,
          }
        }, */