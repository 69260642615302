import axios from "axios"
import apiConfig from "@/core/config/api/api.config"
import store from '../services/store/index'

class AxiosService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...apiConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    const isAbsoluteURLRegex = /^(?:\w+:)\/\//
    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        if (!isAbsoluteURLRegex.test(config.url)) {
          // eslint-disable-next-line no-param-reassign
          config.url = `${this.jwtConfig.baseUrl}${config.url}` // Asignacion de API_URL_BASE
        }
        // Get token from Vue Store
        const accessToken = store.getters.getToken

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error =>  error,
    )
  }

  getToken() {
    
    return this.$store.getters['auth/getToken'];
  }

}

function useAxiosService(axiosIns, jwtOverrideConfig) {
  const axiosService = new AxiosService(axiosIns, jwtOverrideConfig)
  return {
    axiosService,
  }
}

const { axiosService } = useAxiosService(axios, {})
export default axiosService
