import Vue from "vue";
import Vuex from "vuex";

// Persisted Data Plugin
import createPersistedState from 'vuex-persistedstate'
import secureStorage from './secure-storage'

import auth from "./auth.module";
import invitation from "./invitation.module";
import event from "./event.module";
import client from "./client.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    event,
    client,
    invitation,
    htmlClass,
    config,
    breadcrumbs,
    profile,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => secureStorage.getItem(key),
        setItem: (key, value) => secureStorage.setItem(key, value),
        removeItem: key => secureStorage.removeItem(key),
      },
    }),
  ],
});
