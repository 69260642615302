import ApiConfig from "@/core/config/api/api.config"
import axiosService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// import Swal from 'sweetalert2'

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = 'login';
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const SAVE_LAST_PATH = "savelastPath";


// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_TOKEN = "setToken";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_LAST_PATH= "setlastPath";

const state = {
  errors: null,
  user: {},
  token: '',
  isAuthenticated: !!JwtService.getToken(),
  lastPath: '',
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getToken: state => state.token,
  getLastPath(state) {
    return state.lastPath;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.login, credentials)
        .then(({ data }) => {
          if(data){
            context.commit(SET_AUTH, data);
            context.commit(SET_TOKEN, data.token);
            resolve(data);
          } else {
            resolve('NoUser');
          }
        })
        .catch((response) => {
          console.log(response)
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post("login", credentials)
        .then(({ data }) => {
          // context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  /* [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // axiosService.axiosIns.setHeader();
      axiosService.axiosIns.get("verify")
        .then(({ data }) => {
          console.log(data)
          // context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  }, */
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return axiosService.axiosIns.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [SAVE_LAST_PATH](context, credentials) {
    context.commit(SET_LAST_PATH, credentials.params.name);
  },
};

const mutations = {
  [SET_LAST_PATH](state, lastPath) {
    state.lastPath = lastPath;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_TOKEN](state, token) {
    state.token = token
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
