import ApiConfig from "@/core/config/api/api.config"
import axiosService from "@/core/services/api.service";
import Swal from 'sweetalert2'

// action types
export const GET_ALL_EVENTS = "getAllEvents";
export const GET_ALL_CLIENTS_TO_SELECT = "getAllClientsToSelect";
export const GET_ALL_PACKAGE_TO_SELECT = "getAllPackageToSelect";
export const GET_ALL_GROUP_TO_SELECT = "getAllGroupToSelect";
export const GET_ALL_TYPE_GUEST_TO_SELECT = "getAllTypeGuestToSelect";
export const CREATE_EVENT = "createEvents";
export const GET_EVENT_INFO = "getEventInfo";
export const EDIT_EVENT = "editEventInfo";
export const DELETE_EVENT = "deleteEvent";
export const SAVE_INVITATION_PARAMS = "saveInvitationParams";


// mutation types
export const SET_INVITATION_PARAMS = "setInvitationParams";

const state = {
  InvitationParams: {},
};

const getters = {
  getInvitationParams(state) {
    return state.InvitationParams;
  },
};

const actions = {

  [GET_ALL_EVENTS](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.getAllEvents, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_ALL_CLIENTS_TO_SELECT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.get(ApiConfig.getAllClientsToSelect, credentials)
        .then((data) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_ALL_PACKAGE_TO_SELECT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.get(ApiConfig.getAllPackageToSelect, credentials)
        .then((data) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_ALL_GROUP_TO_SELECT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.get(ApiConfig.getAllGroupToSelect, credentials)
        .then((data) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_ALL_TYPE_GUEST_TO_SELECT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.get(ApiConfig.getAllTypeGuestToSelect, credentials)
        .then((data) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [CREATE_EVENT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.createEvents, credentials)
        .then((data ) => {
          if(data.response){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${data.response.data.message}`,
              showConfirmButton: false,
            })
          } else {
            resolve(data);
          }
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_EVENT_INFO](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.get(ApiConfig.getEventInfo + credentials.id)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [EDIT_EVENT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.put(ApiConfig.editEventInfo + credentials.id, credentials)
        .then((data ) => {
          if(data.response){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${data.response.data.message}`,
              showConfirmButton: false,
            })
          } else {
            resolve(data);
          }
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [DELETE_EVENT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.delete(ApiConfig.deleteEvent + credentials.id)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [SAVE_INVITATION_PARAMS](context, credentials) {
    context.commit(SET_INVITATION_PARAMS, credentials);
  },
  
};

const mutations = {
  [SET_INVITATION_PARAMS](state, inviationParams) {
    state.InvitationParams = inviationParams;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
