import ApiConfig from "@/core/config/api/api.config"
import axiosService from "@/core/services/api.service";
import Swal from 'sweetalert2'

// action types
export const GET_ALL_CLIENTS = "getAllClients";
export const CREATE_CLIENT = "createClient";
export const DELETE_CLIENT = "deleteClient";
export const GET_CLIENT_INFO = "getClientInfo";
export const EDIT_CLIENT = "editClient";

// mutation types

const state = {
};

const getters = {
};

const actions = {
  [GET_ALL_CLIENTS](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.getAllClients, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [CREATE_CLIENT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.createClient, credentials)
        .then((data) => {
          if(data.response){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${data.response.data.message}`,
            })
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          console.log(error)
        });
    });
  },
  [EDIT_CLIENT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.put(ApiConfig.editClient, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [DELETE_CLIENT](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.delete(ApiConfig.deleteClient + credentials.id,)
        .then((data) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_CLIENT_INFO](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.getClientInfo + credentials.id)
        .then((data) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
};

const mutations = {
};

export default {
  state,
  actions,
  mutations,
  getters,
};
