import ApiConfig from "@/core/config/api/api.config"
import axiosService from "@/core/services/api.service";
import Swal from 'sweetalert2'

// action types
export const GET_GUESTS_DASHBOARD = "getGuestDashboard";
export const DELETE_GUESTS = "deleteGuest";
export const GET_GUEST_INFO = "getGuestInfo";
export const GET_GLIENT_EVENTS = "getClientEvents";
export const SAVE_EVENT_ID = "saveEventId";
export const CREATE_GUEST = "createGuest";
export const EDIT_GUEST = "editGuest";
export const GET_EVENT_PACKAGE = "getEventPackage";
export const CREATE_GUEST_BY_THE_INVITATION = "createGuestByTheInvitation";
export const GUEST_INFO_CODE = "guestInfoCode";
export const CREATE_GUEST_UPDATE = "createGuestUpdate";



// mutation types
export const SET_EVENT_ID = "setEventId";

const state = {
  EventId: {},
};

const getters = {
  getEventId(state) {
    return state.EventId;
  },
};

const actions = {
  [CREATE_GUEST_UPDATE](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.createGuestUpdate + credentials.slug + '/' + credentials.code, credentials )
        .then((data ) => {
            resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GUEST_INFO_CODE](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.get(ApiConfig.guestInfoCode + credentials.code)
        .then((data ) => {
          console.log('entro', data);
          if(data.response){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${data.response.data.message}`,
              allowOutsideClick: false,
              showConfirmButton: false,
            })
          } else {
            resolve(data);
          }
        })
        .catch((response) => {
          console.log('holisss', response)
        });
    });
  },
  [CREATE_GUEST_BY_THE_INVITATION](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.createGuestByTheInvitation + credentials.Slug, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_GUESTS_DASHBOARD](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.getGuestDashboard, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [DELETE_GUESTS](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.deleteGuest + credentials.id, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_GUEST_INFO](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.getGuestInfo + credentials.id, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_GLIENT_EVENTS](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.get(ApiConfig.getClientEvents, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [CREATE_GUEST](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.createGuest, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [EDIT_GUEST](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.put(ApiConfig.editGuest + credentials.id, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [GET_EVENT_PACKAGE](context, credentials) {
    return new Promise((resolve) => {
      axiosService.axiosIns.post(ApiConfig.getEventPackage, credentials)
        .then((data ) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
        });
    });
  },
  [SAVE_EVENT_ID](context, credentials) {
    context.commit(SET_EVENT_ID, credentials);
  },
};

const mutations = {
  [SET_EVENT_ID](state, credentials) {
    state.EventId = credentials.paramsEvent;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
