export default {
    // Base url path
    // baseUrl: 'https://api.invination.mx/api_invination',
    // baseUrl: 'http://localhost:5000/nodeapp', // MI MAQUINA
    baseUrl: 'https://api.invination.mx/api_invination', // Sebastian Maquina
    // baseUrl: 'http://192.168.150.18:5000/tickets', // Andrei Maquina
    // baseUrl: 'https://ap.healthtelcorp.com/agent_performance_beta_api',
    // baseUrl: 'http://192/nodeapp/',
    // Endpoints
    login: '/api/v1/auth/login',
    verifyAuthyCodeEndpoint: '/api/v1/users/verify',
    registerEndpoint: '/jwt/register',
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
  
    // Endpoints
    signUp: '/api/v1/auth/signup',
    updateUser: '/api/v1/auth/update-user',
    deleteUser: '/api/v1/auth/delete-user',
    getUserById: '/api/v1/auth/get-user',
  
    // Endpoints Tickets
    getTickets: '/api/v1/tickets',
  
    createElementCatalog: '/api/v1/catalogs/create-element',
    createUpdateElement: '/api/v1/catalogs/create-catalog-element',
    getAllElementCatalog: '/api/v1/catalogs/get-simple-catalog',
    getSpecificElementCatalog: '/api/v1/catalogs/get-element-catalog',
    editSpecificElementCatalog: '/api/v1/catalogs/edit-element',
    deleteSpecificElementCatalog: '/api/v1/catalogs/delete-element',

    // Endpoints Cliente
    getAllClients: '/api/v1/auth/users',
    createClient: '/api/v1/auth/register-account',
    deleteClient: '/api/v1/auth/',
    getClientInfo: '/api/v1/auth/get-user/',
    editClient: '/api/v1/auth/update-user',
    
    // Endpoints Eventos
    getAllEvents: '/api/v1/events/',
    getAllClientsToSelect: '/api/v1/events/get-clients',
    getAllPackageToSelect: '/api/v1/events/get-packages',
    getAllGroupToSelect: '/api/v1/events/get-event-groups',
    getAllTypeGuestToSelect: '/api/v1/events/get-guests-types',
    createEvents: '/api/v1/events/create-event',
    getEventInfo: '/api/v1/events/get-event-info/',
    editEventInfo: '/api/v1/events/',
    deleteEvent: '/api/v1/events/',

    // Endpoints client Dashboard
    getGuestDashboard: '/api/v1/invitations/get-guests-dashboard',
    deleteGuest: '/api/v1/invitations/delete-guest/',
    getGuestInfo: '/api/v1/invitations/get-guest/',
    getClientEvents: '/api/v1/events/get-client-events',
    createGuest: '/api/v1/invitations/create-guest',
    editGuest: '/api/v1/invitations/edit-guest/',
    getEventPackage: '/api/v1/invitations/event-package',

    // Endpoints Invitation
    createGuestByTheInvitation: '/api/v1/invitations/create-guest/',
    guestInfoCode: '/api/v1/invitations/guest-info-code/',
    createGuestUpdate: '/api/v1/invitations/create-guest/',
    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',
  
    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
  }
  